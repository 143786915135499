import React, {useState} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {Card, CardContent, CardFooter, CardHeader, CardTitle} from "./@/components/ui/card";
import {Button} from "./@/components/ui/button";
import {Input} from "./@/components/ui/input";
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';

function Register() {
  const {t} = useTranslation();
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/register`, {
        username,
        firstName,
        lastName,
        email,
        password
      });
      if (response.status === 201) {
        navigate('/verify', {state: {email}});
      }
    } catch (error) {
      if (error.response && error.response.data.message === 'duplicate_email') {
        toast.error(t('register_duplicate_email'), {
          position: "top-center",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        console.error('Registration error:', error);
      }
    }
  };

  return (
    <div className="w-full h-full flex justify-center items-center bg-muted py-4 px-2 sm:py-10 sm:px-5">

      <div className="absolute bottom-2 left-1 m-4 px-3 py-2 w-[50%-55px]">
        <p className="text-white text-lg text-left space-y-2 hidden">"Jupiter m'a permis d'améliorer la gestion de mes
          résulats scolaires et m'a aidé à me focaliser sur mes objectifs." - <span
            className="font-bold">John Doe</span></p>
      </div>

      <div className="hidden sm:flex w-1/2 h-full relative bg-green-500 border m-5 rounded-3xl" id="g1">
        <div className="absolute top-2 left-1 m-4 px-3 py-2">
          <a href="/" className="text-xl text-white font-medium text-center">
            <i className="fas fa-chart-line mr-2"></i>
            ImproveGrade.com
          </a>
        </div>

        <div className="absolute bottom-2 left-1 m-4 px-3 py-2">
          <p className="text-center ml-2 text-white font-medium">
            {t('footer_developed_by')} <a href="https://bento.me/soren-starck" target="_blank" rel="noreferrer"
                                          className="underline">
            Soren STARCK
          </a>
          </p>
        </div>
      </div>

      <Card
        className="w-full sm:w-1/2 h-full relative bg-background m-2 sm:m-5 rounded-3xl border-none shadow-none flex flex-col justify-center">

        <div className="absolute top-2 right-1 m-4 hidden sm:flex">
          <a href="/login" className="nav-item text-white">
            <i className="fas fa-sign-in-alt mr-2"></i>
            {t('login')}
          </a>
        </div>

        <form onSubmit={handleSubmit} className="flex flex-col gap-4 p-4 my-auto w-full sm:w-3/4 mx-auto">

          <CardHeader>
            <CardTitle className="sm:text-4xl">
              {t('register_title')}
            </CardTitle>
          </CardHeader>

          <CardContent className="flex flex-col gap-6 sm:my-10">
            <Input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required
                   placeholder={t('username_placeholder')}/>
            <Input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required
                   placeholder={t('first_name_placeholder')}/>
            <Input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} required
                   placeholder={t('last_name_placeholder')}/>
            <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required
                   placeholder={t('email_placeholder')}/>
            <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required
                   placeholder={t('password_placeholder')}/>
          </CardContent>

          <CardFooter className="flex flex-col gap-2">
            <Button type="submit" className="w-full">
              <i className="fas fa-user-plus mr-2"></i>
              {t('register_button')}
            </Button>
            <p className="text-center text-gray-500 text-sm">{t('terms_conditions')}</p>
          </CardFooter>
        </form>
      </Card>

    </div>
  );
}

export default Register;