import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Card, CardContent, CardFooter, CardHeader, CardTitle} from "./@/components/ui/card";
import {Badge} from "./@/components/ui/badge";
import {ContextMenu, ContextMenuContent, ContextMenuItem, ContextMenuTrigger} from "./@/components/ui/context-menu";
import axios from 'axios';
import CryptoJS from 'crypto-js';

function TableauCard({tableau, userCount, style, className, favorite, isAdmin}) {
  const navigate = useNavigate();

  const handleCardClick = () => {
    localStorage.removeItem('tableauxCache');
    const encryptedId = CryptoJS.AES.encrypt(tableau.idtableau.toString(), 'secret-key').toString();
    navigate(`/tableau/${encodeURIComponent(encryptedId)}`);
  }

  const handleDeleteTableau = async (event) => {
    event.stopPropagation();
    try {
      const userId = localStorage.getItem('userId');
      console.log("delete tableau", tableau.idtableau);
      await axios.delete(`${process.env.REACT_APP_SERVER_URL}/tableaux/${tableau.idtableau}`, {
        headers: {'user-id': userId}
      });
      localStorage.removeItem('tableauxCache');
      window.location.reload();
    } catch (error) {
      console.error('Error deleting tableau:', error);
    }
  };

  const handleSwitchCertified = async (event) => {
    event.stopPropagation();
    try {
      const userId = localStorage.getItem('userId');
      localStorage.removeItem(tableau.idtableau);
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/tableaux/${tableau.idtableau}/certify`, {
        isCertified: !tableau.isCertified,
      }, {
        headers: {
          'user-id': userId
        }
      });
      localStorage.removeItem('tableauxCache');
      window.location.reload();
    } catch (error) {
      console.error('Error switching certified status:', error);
    }
  };

  return (
    <Card className={`m-2 p-2 overflow-hidden hover:cursor-pointer h-min text-sm ${className}`} style={style}
          onClick={handleCardClick}>
      {isAdmin ? (
        <ContextMenu>
          <ContextMenuTrigger>
            <div className="tableau-card">
              <CardHeader className="p-1">
                <CardTitle className="text-md whitespace-nowrap">
                  {tableau.iscertified && (
                    <i className="fas fa-check-circle text-blue-500 drop-shadow-xl mr-1 dark:text-blue-500"></i>
                  )}
                  {tableau.nomtableau}</CardTitle>
              </CardHeader>
              <CardContent className="p-1 flex flex-row justify-between">
                <div className="flex flex-col gap-1">
                  <Badge variant="outline" className="text-xs w-fit">
                    <i className="fas fa-user mr-1.5"></i>
                    {tableau.login}</Badge>
                  <Badge className="text-xs w-fit">
                    <i className="fas fa-graduation-cap mr-1.5"></i>
                    {tableau.formationlevel}</Badge>
                </div>
                <div className="flex flex-col justify-end gap-1 ml-auto items-end">
                  <Badge variant="outline" className="text-xs w-fit">
                    <i className="fas fa-calendar-alt mr-1.5"></i>
                    {tableau.semester}
                  </Badge>
                  <Badge variant="outline" className="text-xs w-fit">
                    <i className="fas fa-users mr-1.5"></i>
                    {userCount}
                  </Badge>
                </div>
                {favorite && (
                  <i className="fas fa-star text--primary drop-shadow-xl absolute top-3.5 right-3.5"></i>
                )}
              </CardContent>
            </div>
          </ContextMenuTrigger>
          <ContextMenuContent>
            <ContextMenuItem onClick={handleDeleteTableau}>Delete</ContextMenuItem>
            <ContextMenuItem onClick={handleSwitchCertified}>
              {tableau.isCertified ? 'Uncertify' : 'Certify'}
            </ContextMenuItem>
          </ContextMenuContent>
        </ContextMenu>
      ) : (
        <div>
          <CardHeader className="p-1">
            <CardTitle className="text-md whitespace-nowrap">
              {tableau.iscertified && (
                <i className="fas fa-check-circle text-blue-500 drop-shadow-xl mr-1 dark:text-blue-500"></i>
              )}
              {tableau.nomtableau}</CardTitle>
          </CardHeader>
          <CardContent className="p-1 flex flex-row justify-between">
            <div className="flex flex-col gap-1">
              <Badge variant="outline" className="text-xs w-fit">
                <i className="fas fa-user mr-1.5"></i>
                {tableau.login}</Badge>
              <Badge className="text-xs w-fit">
                <i className="fas fa-graduation-cap mr-1.5"></i>
                {tableau.formationlevel}</Badge>
            </div>
            <div className="flex flex-col justify-end gap-1 ml-auto items-end">
              <Badge variant="outline" className="text-xs w-fit">
                <i className="fas fa-calendar-alt mr-1.5"></i>
                {tableau.semester}
              </Badge>
              <Badge variant="outline" className="text-xs w-fit">
                <i className="fas fa-users mr-1.5"></i>
                {userCount}
              </Badge>
            </div>
            {favorite && (
              <i className="fas fa-star text--primary drop-shadow-xl absolute top-3.5 right-3.5"></i>
            )}
          </CardContent>
        </div>
      )}
    </Card>
  );
}

export default TableauCard;